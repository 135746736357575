// special styles for j233
.j233 {
  a {
    font-weight: 700;
    color: #357bb3;
    &:visited {
      color: #357bb3;
    }
    &:hover, &:focus, &:active {
      color: #14568b;
    }
  }
}


.schedule {
  // background-color: #f9f9f9;
  // padding: 0.1rem 2rem 1rem;
  h3 {
    border-top: 2px solid #ccc;
    padding-top: 1rem;
    &:first-child {
      border-top: 0 none transparent;
    }

  }

  h5 {
    // color: #e21d60;
    font-size: smaller;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
  }
}

table {
  width: 100%;
  th, td {
    vertical-align: top;
  }
  td {
    border-top: 1px solid #ccc;
  }
  th:nth-child(1), td:nth-child(1) {
    text-align: left;
  }
  th:nth-child(2), td:nth-child(2) {
    text-align: right;
  }
}