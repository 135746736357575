@import '_vars.scss';
@import '_defaults.scss';

.container {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.alert {
  // width: calc(100% - 4rem - 2px);
  // margin-top: 2rem;
  padding: 1rem 2rem;
  background-color: $yellow;
  border: 1px solid darken($yellow, 10%);
}

@import '_j233.scss';

@import '_footer.scss';
