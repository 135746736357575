body {
  font-weight: 300;
  font-family: $sans-serif;
  color: $text-color;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $monospace;
  // margin-top: 2rem;
}

h1, h2, h3 {
  color: #333;
  font-weight: 700;
}

h4, h5, h6 {
  color: #555;
  font-weight: 400;
}

code, pre {
  font-family: $monospace;
  font-weight: 400;
  color: #666;
}

blockquote {
  border-left: 5px solid #ccc;
  margin: 0;
  padding: 0 1rem;
}

ul {
  padding-left: 1rem;
  list-style-type: square;
}

summary {
  cursor: pointer;
}

.emoji-icon {
  display: inline-block;
  transform: scale(1.3, 1.3);
  margin-left: 3px;
}

// special styles for home page
.index {
  a {
    font-weight: 300;
    color: $text-color;
    text-decoration: none;
    padding: 0 3px;
    box-shadow: inset 0 -7px 0 0 $link-color;
    &:hover, &:visited, &:focus, &:active {
      box-shadow: inset 0 -7px 0 0 $visited-color;
    }
  }
}
