@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700,900");

$sans-serif: 'Roboto', Helvetica, Arial, sans-serif;
$monospace: 'Roboto Mono', Courier, monospace;

$text-color: #111;
$hed-color: #333;
$link-color: #c1f3f5;
$visited-color: #bfd9da;
$yellow: #fff3cd;